import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type FormInputComponent } from '@vendure/admin-ui/core';
import type { FormControl } from '@angular/forms';
import { type StringCustomFieldConfig } from '@vendure/core';
import { z } from 'zod';

export const platformSchema = z.array(z.enum(['app', 'webshop']));

/**
 * @description
 * A form control for selecting a promotion platform.
 *
 * @docsCategory components
 */
@Component({
  selector: 'platform-selector',
  templateUrl: './platform-selector.component.html',
  styleUrls: ['./platform-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformSelectorComponent
  implements FormInputComponent<StringCustomFieldConfig>
{
  config!: StringCustomFieldConfig;
  formControl!: FormControl<string>;
  @Input() readonly!: boolean;

  readonly platforms = ['app', 'webshop'];
  value: string[] = [];

  ngOnInit() {
    const parsed = platformSchema.safeParse(JSON.parse(this.formControl.value));

    if (parsed.success) {
      this.value = parsed.data;
    } else {
      console.error(parsed.error);
      this.value = ['app', 'webshop'];
    }
  }

  onChange(selected: string[]) {
    this.formControl.setValue(JSON.stringify(selected));
    this.formControl.markAsDirty();
  }
}

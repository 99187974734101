import { NgModule } from '@angular/core';
import { addNavMenuItem, SharedModule } from '@vendure/admin-ui/core';

@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuItem(
      {
        id: 'customisable-cta',
        label: 'Customisable CTA',
        routerLink: ['/extensions/customisable-cta'],
        icon: 'block',
        requiresPermission: 'SetCustomisableCta',
      },
      'marketing',
    ),
  ],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CustomisableNavModule {}

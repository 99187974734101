import { NgModule } from '@angular/core';
import { addNavMenuItem, SharedModule } from '@vendure/admin-ui/core';

/**
 * This module adds the banners to existing marketing nav
 */
@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuItem(
      {
        id: 'shop-banners',
        label: 'Shop Banners',
        routerLink: ['/extensions/shop-banners'],
        requiresPermission: 'ReadShopBanner',
        icon: 'announcement',
      },
      'marketing',
    ),
  ],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ShopBannersNavModule {}

import { NgModule } from '@angular/core';
import { addNavMenuItem, SharedModule } from '@vendure/admin-ui/core';

/**
 * This module adds the coupons-sections to existing nav
 */
@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuItem(
      {
        id: 'coupons',
        label: 'Coupons',
        routerLink: ['/extensions/coupons'],
        icon: 'star',
        requiresPermission: 'ReadCoupon',
      },
      'marketing',
    ),
  ],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CouponsNavModule {}

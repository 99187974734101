<ng-select
  [items]="platforms"
  appendTo="body"
  [disabled]="readonly"
  [ngModel]="value"
  (change)="onChange($event)"
  width="100%"
  [multiple]="true"
>
</ng-select>

import { NgModule } from '@angular/core';
import {
  SharedModule,
  registerFormInputComponent,
} from '@vendure/admin-ui/core';
import { BrandLogoInputComponent } from './components/brand-logo-input/brand-logo-input.component';
import { PlatformSelectorComponent } from './components/platform-selector/platform-selector.component';

@NgModule({
  imports: [SharedModule],
  providers: [
    registerFormInputComponent('brand-logo-input', BrandLogoInputComponent),
    registerFormInputComponent('platform-selector', PlatformSelectorComponent),
  ],
  declarations: [BrandLogoInputComponent, PlatformSelectorComponent],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SharedUIModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouponsNavModule } from './extensions/a98722ba66a854da871e0a704dfada0255863c9cfb8b33393a85115f6892ba0b/coupons-nav.module';
import { SharedUIModule } from './extensions/a98722ba66a854da871e0a704dfada0255863c9cfb8b33393a85115f6892ba0b/shared-ui.module';
import { ShopBannersNavModule } from './extensions/b5cc25aab6ab43b847dddfb19ba7f7f83083d1ac51735b7857843551c3a02d66/nav.module';
import { CustomisableNavModule } from './extensions/a5e39924f7c6de956c638d3ea030182fabd86dc294cce22329075e5e3688fbc0/nav.module';
import { WebhookNavModule } from './extensions/e252e713b1f4bef5967c2a69e751e76cc8ed72289ebeb11c9ace26a9559c9868/webhook-nav.module';

import SharedProviders_2_0 from './extensions/dashboard-widgets/provider';


@NgModule({
    imports: [CommonModule, CouponsNavModule, SharedUIModule, ShopBannersNavModule, CustomisableNavModule, WebhookNavModule],
    providers: [...SharedProviders_2_0],
})
export class SharedExtensionsModule {}

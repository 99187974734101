import {
  registerDashboardWidget,
  setDashboardWidgetLayout,
} from '@vendure/admin-ui/core';

export default [
  registerDashboardWidget('orderOrigins', {
    title: 'Order Origins',
    supportedWidths: [6],
    loadComponent: async () =>
      (
        await import(
          './components/order-origin-widget/order-origin-widget.component'
        )
      ).OrderOriginWidgetComponent,
  }),
  // Override the orders summary widget with our custom one to
  // include the opt-in rate.
  registerDashboardWidget('orderSummary', {
    title: 'dashboard.orders-summary',
    supportedWidths: [4, 6, 8, 12],
    loadComponent: async () =>
      (await import('./components/order-summary/order-summary.component'))
        .OrderSummaryWidgetComponent,
  }),
  setDashboardWidgetLayout([
    { id: 'metrics', width: 12 },
    { id: 'orderSummary', width: 6 },
    { id: 'orderOrigins', width: 6 },
    { id: 'latestOrders', width: 12 },
  ]),
];
